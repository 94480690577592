import { siteMetadata } from "../../gatsby-config"

export default async (executeRecaptcha) => {
  if (typeof executeRecaptcha !== `function`) return false

  const token = await executeRecaptcha('submit')
  //const token = 'asdfasdfasdf'

  // --> validate result, and then handle submit
  const result = await fetch(siteMetadata.drupalUrl+'/recaptcha/verify/'+token, {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-type': 'application/json' },
  })
  const responseData = await result.json()
  let validation;
  if (responseData.data) {
    validation = JSON.parse(responseData.data)
  }
  if (validation && 
      validation.verification && 
      validation.verification.success && 
      validation.verification.score >= 0.5) {
    if (validation.verification.test) console.log('recaptcha test mode enabled');
    return true
  }
  return false
  //const submitRet = await handleSubmit(e)
  //if (submitRet) return submitRet
}
