import { navigate } from "gatsby"
import { Button, Form, TextInput} from 'carbon-components-react';
import React, {useState} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../util/recaptchaSubmit'
import {
  siteMetadata
} from "../../gatsby-config"

async function jsonRequest(endpoint, method, payload){
  const url = `${siteMetadata.drupalUrl}/${endpoint}`;
  const init = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  }
  return fetch(url,init);
}

/*
function ResetPassword({location, setError}) {
  const userContext = useContext(UserContext);
  const makeResetRequest = (name) => {
    const response = await jsonRequest("user/lost-password?_format=json", "POST", payload); 
  }
}
*/

export function SetNewPasswordForm() {
  const [username, setUsername] = useState("")
  const [tempName, setTempName] = useState("")
  const [tempPass, setTempPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmNewPass, setConfirmNewPass] = useState("")
  const [message, setMessage] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch(name) {
      case 'username':
        setUsername(value)
        break
      case 'tempName':
        setTempName(value)
        break
      case 'tempPass':
        setTempPass(value)
        break
      case 'newPass':
        setNewPass(value)
        break
      case 'confirmNewPass':
        setConfirmNewPass(value)
        break
      default:
        console.log('field has no state')
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();
    event.persist();

    try {
      // const valid = await recaptchaSubmit(executeRecaptcha)

      const payload = {
        name: username,
        temp_pass: tempPass,
        new_pass: newPass,

      };

      const response = await jsonRequest("user/lost-password-reset?_format=json", "POST", payload);

      if(response.ok){
        navigate('/login')
      }else{
        setMessage("Unable to reset password.");
      }
    } catch (err) {
      setMessage("Unable to reset password.");
    }
  }

  return (
    <>
      <div>
        <p>You should receive an email shortly with a temporary password</p>
        <p>Please use it here to reset your password</p>
      </div>
      <Form className="reset-form" onSubmit={handleSubmit}>
        {message}
        <TextInput
          light
          required
          placeholder="Username"
          type="text"
          name="username"
          value={username}
          onChange={handleInputChange}
        />
        <TextInput 
          light
          required
          placeholder="Temporary Password"
          type="password"
          name="tempPass"
          value={tempPass}
          onChange={handleInputChange}
        />
        <TextInput 
          light
          required
          placeholder="New Password"
          type="password"
          name="newPass"
          value={newPass}
          onChange={handleInputChange}
        />
        <Button type="submit">Change password</Button>
      </Form>
    </>
  )
}

export default function ResetPasswordPage({location}) {
  const [userEmail,setUserEmail] = useState("")
  const [ok,setOk] = useState(false)
  const [message, setMessage] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha();
  let pageContext = {frontmatter: {title: 'Reset Password'},
  relativePagePath: "/password-reset.js",
  titleType: "page"}
  pageContext = {...pageContext, frontmatter: {title: "Reset Password"}};

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setUserEmail(value);
  }

  const handleSubmit = async event => {
    event.preventDefault();
    event.persist()

    try {      
      const valid = await recaptchaSubmit(executeRecaptcha)
      if (!valid) throw new Error("Failed recaptcha")
      const payload = {mail: userEmail};
      console.log(payload)
      const response = await jsonRequest("user/lost-password?_format=json", "POST", payload);

      if(response.ok){
        setOk(true);
      }else{
        setMessage("Unable to send reset password email");
      }
    } catch (err) {
      setMessage(err.message);
    }
  }
  return (     
    // <Default pageContext={pageContext} location={location}>
    // {
      !ok ? (
        <>
          <div>
            <p>Enter email associated with the account you would like to password reset</p>
          </div>
          <Form className="reset-form" onSubmit={
            async (e) => {
              e.preventDefault();
              try {
                let handle = await handleSubmit(e);
              } catch (err) {
                setMessage(err.message);
              }
            }
          }>
            <TextInput
              light
              required
              type="text"
              required
              placeHolder="Email"
              name="userEmail"
              id="userEmail"
              onChange={handleInputChange}
            />
            <Button type="submit">Reset Password</Button>
          </Form>
        </>
      ) : (<SetNewPasswordForm />)
    // }
    // </Default>
  )
}
